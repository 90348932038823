import { Box, Button, Grid, Stack, styled, useTheme } from "@mui/material";
import React, { useRef } from "react";
import data from "../utils/index.json";
import html2canvas from "html2canvas";
import CasinoIcon from "@mui/icons-material/Casino";
import DownloadIcon from "@mui/icons-material/Download";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const MemeExpressions = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const MemeEyes = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const MemeGestures = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const MemeHats = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const MemeWings = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  zIndex: -1,
}));

const AvatarLoader = (props) => {
  const theme = useTheme();
  const boxRef = useRef();

  const handleGenerate = () => {
    const randomMeme = {
      background: getRandomId(data.background),
      expressions: getRandomId(data.expressions),
      EYE: getRandomId(data.EYE),
      gesture: getRandomId(data.gesture),
      hat: getRandomId(data.hat),
      // wings: getRandomId(data.wings),
    };

    function getRandomId(obj) {
      const keys = Object.keys(obj);
      const randomIndex = Math.floor(Math.random() * keys.length);
      return keys[randomIndex];
    }

    props.setMeme(randomMeme);
  };

  const download = () => {
    html2canvas(boxRef.current).then((canvas) => {
      const link = document.createElement("a");
      link.download = "download.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };
  const handleReset = () => {
    props.setMeme({
      background: "BG2",
      expressions: "EXP1",
      EYE: "",
      gesture: "",
      hat: "",
    });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* Background */}
        {data.background[props.meme.background] ? (
          <Box
            ref={boxRef}
            sx={{
              borderRadius: theme.spacing(1),
              height: "450px",
              width: "100%",
              backgroundImage: `url(${require(`../assets/images/BACKGROUNDS/${
                data.background[props.meme.background]
              }`)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              position: "relative",
              zIndex: 100,

              [theme.breakpoints.down("md")]: {
                height: "350px",
              },
            }}
          >
            {/* Expressions */}
            {data.expressions[props.meme.expressions] ? (
              <MemeExpressions
                component={"img"}
                src={require(`../assets/images/EXPRESSIONS/${
                  data.expressions[props.meme.expressions]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Eye */}
            {data.EYE[props.meme.EYE] ? (
              <MemeEyes
                component={"img"}
                src={require(`../assets/images/EYE/${
                  data.EYE[props.meme.EYE]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Gesture */}
            {data.gesture[props.meme.gesture] ? (
              <MemeGestures
                component={"img"}
                src={require(`../assets/images/GESTURE/${
                  data.gesture[props.meme.gesture]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Hats */}
            {data.hat[props.meme.hat] ? (
              <MemeHats
                component={"img"}
                src={require(`../assets/images/HAT/${
                  data.hat[props.meme.hat]
                }`)}
              />
            ) : (
              <></>
            )}

            {/* Wings */}
            {/* {data.wings[props.meme.wings] ? (
              <MemeWings
                component={"img"}
                src={require(`../assets/images/WINGS/${
                  data.wings[props.meme.wings]
                }`)}
              />
            ) : (
              <></>
            )} */}
          </Box>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12} mt={5}>
        <Stack direction={"row"} gap={3}>
          <Button
            sx={{
              // height: 64,
              fontSize: 16,
              [theme.breakpoints.down("md")]: {
                height: 48,
                fontSize: 16,
              },
            }}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            startIcon={<RotateLeftIcon />}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            sx={{
              // height: 64,
              fontSize: 16,
              [theme.breakpoints.down("md")]: {
                height: 48,
                fontSize: 16,
              },
            }}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            onClick={handleGenerate}
            startIcon={<CasinoIcon />}
          >
            Randomize
          </Button>
          <Button
            sx={{
              // height: 64,
              fontSize: 16,
              [theme.breakpoints.down("md")]: {
                height: 48,
                fontSize: 16,
              },
            }}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            onClick={download}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AvatarLoader;
