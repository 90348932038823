import { Box, Grid, Tab, Tabs, styled, useTheme } from "@mui/material";
import React, { useState } from "react";
import NoneImg from "../assets/images/none.png";
import data from "../utils/index.json";

const PageWrapper = styled(Box)(({ theme }) => ({
  height: "80vh", // Fill most of the height of the viewport
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
  border: `2px solid ${theme.palette.text.secondary}`,
  borderRadius: theme.spacing(2),
}));

const TabsWrapper = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  borderBottom: `1px solid ${theme.palette.divider}`, // Add a divider below the tabs for separation
}));

// Custom Tab styles
const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    minHeight: 48, // Set a fixed height for tabs
    borderBottom: `2px solid transparent`, // Transparent border by default
    fontWeight: theme.typography.fontWeightMedium,
    transition: "color 0.3s ease",
    "&:hover": {
      color: theme.palette.text.primary, // Color for selected tab
    },
    "&.Mui-selected": {
      color: theme.palette.text.primary, // Color for selected tab
      borderBottom: `2px solid ${theme.palette.text.primary}`, // Bottom border for selected tab
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  "& .MuiTabs-indicator": {
    display: "none", // Hide default Material-UI indicator
  },
}));

const TabContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  padding: theme.spacing(2),
  "::-webkit-scrollbar": {
    width: "0.4em",
  },
  "::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "10px",
  },
  scrollbarWidth: "none",
  "-ms-overflow-style": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const MiniBox = styled(Grid)(({ theme }) => ({
  height: 120,
}));

const MinBoxImg = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  background: theme.palette.secondary.main,
  border: `2px solid ${theme.palette.text.secondary}`,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  ":hover": {
    border: `2px solid ${theme.palette.text.primary}`,
    background: theme.palette.primary.light,
  },

  height: "100%",
  width: "100%",
}));

const MiniBoxList = ({ items, selectedItem, onSelectItem, basePath }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      {Object.entries(items).map(([key, value]) => (
        <MiniBox item xs={3} md={2} key={key} onClick={() => onSelectItem(key)}>
          {value ? (
            <MinBoxImg
              component="img"
              src={require(`../assets/images/${basePath}/${value}`)}
              sx={{
                border:
                  selectedItem === key &&
                  `2px solid ${theme.palette.text.primary}`,
              }}
            />
          ) : (
            <MinBoxImg onClick={() => onSelectItem("")}>
              <Box
                component="img"
                src={NoneImg}
                sx={{
                  height: 60,
                  width: 60,
                  cursor: "pointer",
                }}
              />
            </MinBoxImg>
          )}
        </MiniBox>
      ))}
    </Grid>
  );
};

const Editor = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabContent = [
    {
      label: "Background",
      content: (
        <MiniBoxList
          items={data.background}
          selectedItem={props.meme.background}
          onSelectItem={(key) =>
            props.setMeme({ ...props.meme, background: key })
          }
          basePath="BACKGROUNDS"
        />
      ),
    },
    {
      label: "Expressions",
      content: (
        <MiniBoxList
          items={data.expressions}
          selectedItem={props.meme.expressions}
          onSelectItem={(key) =>
            props.setMeme({ ...props.meme, expressions: key })
          }
          basePath="EXPRESSIONS"
        />
      ),
    },
    {
      label: "EyeWhere",
      content: (
        <MiniBoxList
          items={data.EYE}
          selectedItem={props.meme.EYE}
          onSelectItem={(key) => props.setMeme({ ...props.meme, EYE: key })}
          basePath="EYE"
        />
      ),
    },
    {
      label: "Gestures",
      content: (
        <MiniBoxList
          items={data.gesture}
          selectedItem={props.meme.gesture}
          onSelectItem={(key) => props.setMeme({ ...props.meme, gesture: key })}
          basePath="GESTURE"
        />
      ),
    },
    {
      label: "Hats",
      content: (
        <MiniBoxList
          items={data.hat}
          selectedItem={props.meme.hat}
          onSelectItem={(key) => props.setMeme({ ...props.meme, hat: key })}
          basePath="HAT"
        />
      ),
    },
  ];

  return (
    <PageWrapper>
      {/* Tabs Container */}
      <TabsWrapper>
        <CustomTabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
        >
          {tabContent.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </CustomTabs>
      </TabsWrapper>

      {/* Tab Content Container */}
      <TabContentWrapper>{tabContent[selectedTab].content}</TabContentWrapper>
    </PageWrapper>
  );
};

export default Editor;
