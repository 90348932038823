import { styled } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const PageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "100vh", // Ensure the wrapper takes up the full height of the viewport
  background: "white",
  overflow: "hidden",

  [theme.breakpoints.down("md")]: {
    backgroundPosition: "left center",
  },
}));

const MainWrapper = styled("div")(({ theme }) => ({
  flexGrow: 1, // Ensure MainWrapper grows to fill the available space
  maxWidth: "1440px",
  margin: "0 auto", // Center the content horizontally
  width: "100%", // Full width of the container

  // Media query for smaller screens
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const Layout = () => {
  return (
    <PageWrapper>
      <Header />
      <MainWrapper>
        <Outlet />
      </MainWrapper>
      {/* <Footer /> */}
    </PageWrapper>
  );
};

export default Layout;
