import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import AvatarLoader from "../components/AvatarLoader";
import Editor from "../components/Editor";

const EditorPage = () => {
  const [meme, setMeme] = useState({
    background: "BG1",
    expressions: "EXP1",
    EYE: "",
    gesture: "",
    hat: "",
    // wings: "",
  });
  useEffect(() => {
    console.log("meme", meme);
  }, [meme]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Grid container spacing={5} justifyContent={"center"}>
      <Grid item xs={12} md={7} order={isSmallScreen ? 2 : 1}>
        <Editor meme={meme} setMeme={setMeme} />
      </Grid>

      <Grid item xs={12} md={4} order={isSmallScreen ? 1 : 2}>
        <AvatarLoader meme={meme} setMeme={setMeme} />
      </Grid>
    </Grid>
  );
};

export default EditorPage;
