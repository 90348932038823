import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StickyGrid = styled(Box)(({ theme }) => ({
  margin: "30px auto",

  [theme.breakpoints.down("md")]: {
    padding: "0 ",
  },
}));

const Header = () => {
  return (
    <StickyGrid>
      <Typography fontWeight={600} fontSize={40}>
        JOE COIN 2024
      </Typography>
    </StickyGrid>
  );
};

export default Header;
